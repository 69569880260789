import React from "react";

export const DownloadFunctionality = ({ arrayHref }: any) => {
  return (
    <div className="flex gap-8 items-center">
      {arrayHref.map((item: any) => (
        <a
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
          className="sm:w-4/5 md:w-3/4 lg:w-2/3 xl:w-full h-full hover:cursor-pointer hover:scale-105 transition-all duration-300 active:scale-100"
        >
          <img
            src={item.src}
            alt={item.alt}
            className="sm:w-4/5 md:w-3/4 lg:w-2/3 xl:w-full h-full hover:cursor-pointer hover:scale-105 transition-all duration-300 active:scale-100"
          />
        </a>
      ))}
    </div>
  );
};
