import { motion } from "framer-motion";

export const CasualSweeperScreen = () => {
  return (
    <div className="flex flex-1 flex-col relative">
      <div className="flex justify-between">
        <div className="flex justify-start absolute top-0 left-0">
          <img
            src="/casual_tl.png"
            alt="casual_tl"
            className="w-1/2 2xl:w-2/3"
          />
        </div>
        <div className="flex justify-end absolute top-0 right-0">
          <img
            src="/casual_tr.png"
            alt="casual_tr"
            className="w-1/2 2xl:w-2/3"
          />
        </div>
      </div>
      <div className="flex flex-col gap-4 flex-1 items-center justify-center">
        <div className="flex justify-center">
          <motion.img
            src="/casual_bomb.png"
            alt="casual_bomb"
            className="sm:w-4/5 md:w-3/4 lg:w-2/3 xl:w-4/5"
            whileHover={{
              scale: 1.15, // Slight zoom-in
              rotate: [0, -5, 5, -3, 3, 0], // Subtle rotation effect
              rotateY: 15, // 3D tilt effect
              rotateX: 5, // Slight forward tilt
              transition: { duration: 0.6, ease: "easeInOut" },
            }}
            whileTap={{
              scale: 0.95, // Slight press-down effect when clicked
              rotateY: 0, // Resets tilt
              rotateX: 0,
              transition: { duration: 0.2 },
            }}
          />
        </div>
        <div className="flex justify-center">
          <div className="bg-casual-color px-6 py-2 rounded-3xl">
            <div className="text-2xl md:text-3xl text-gray-500 animate-pulse-slow">
              Coming Soon...
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-start absolute bottom-0 left-0">
        <img src="/casual_bl.png" alt="casual_bl" className="w-1/2 2xl:w-2/3" />
      </div>
      <div className="flex justify-end absolute bottom-0 right-0">
        <img src="/casual_br.png" alt="casual_bl" className="w-1/2 2xl:w-2/3" />
      </div>
    </div>
  );
};
